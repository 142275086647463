<template>
  <div @click="liveJumpRed" v-if="liveRedPacket">
    <img style="position: fixed;right: 0;z-index: 99;width: 20%;" src="@/assets/img/liveRoom/redrain.png" alt="">
  </div>
  <teleport to="body">
    <div class="luck-draw">
      <div class="toast"></div>
      <div class="red-package-content">
        <!-- 抽奖 -->
        <div class="lottery">
          <div class="lotterydiv">
            <div class="lotteryh3">正在抽奖</div>
            <div class="lotteryclose"></div>
            <div class="lotterybox">
              <div class="lotterytext2">中奖者</div>
              <div class="lotteryname"></div>
            </div>
          </div>
          <div class="maskbg"></div>
        </div>
      </div>
    </div>

    <!-- <div class="modal" v-show="visible">
      <div class="modal-header">温馨提示</div>
      <div class="modal-content">
        好友通过您分享的链接进入直播间并登录，视为邀请成功~
      </div>
      <div class="modal-footer">
        <div @click="close">取消</div>
        <div class="confirm confirm-copy" :data-clipboard-text="urlData" @click="confirm">复制</div>
      </div>
    </div>
    <div v-show="visible" class="toast-modal"></div> -->

    <div class="modal" v-show="visiblePic">
      <div class="modal-header">温馨提示</div>
      <div class="modal-content">
        <div style="text-align: left;margin-bottom: 10px;">好友通过您分享的海报进入直播间并登录，视为邀请成功。 请长按下方图片保存</div>

        <img :src="codeImg" alt="" style="width: 100%;height: 100%;">
      </div>
      <div class="modal-footer">
        <div @click="closePic">取消</div>
        <!-- <div class="confirm confirm-copy" :data-clipboard-text="urlData" @click="confirm">复制</div> -->
      </div>
    </div>
    <div v-show="visiblePic" class="toast-modal"></div>

  </teleport>

  <div class="vrt-wrap">
    <!-- intro.courseType == 0 -->
    <!-- v-if="live.liveStatus == 3" --> 
    <div class="vrt-main-video" v-if="live.liveStatus == 3 && errorMsgStr != 'NO_RECORD'">
      <!-- 录播 -->
      <div id="playbackPanel"></div>
      <p id="countDown"></p>
    </div>
    <div class="vrt-main-video" v-else>
   
      <div id="drawPanel"></div>
      <p id="countDown"></p>
      <div class="live-player-video">
        <video
          src="http://1256414354.vod2.myqcloud.com/14bebfc2vodgzp1256414354/bab4a06f387702307458055163/7f1XZ1vQTwgA.mp4"
          id="livePlayerVideo"
          loop
          autoplay
          controls
          poster="https://yingzegbw.oss-cn-beijing.aliyuncs.com/eventbg/livewait.jpg"
        ></video>
      </div>
    </div>
    <div class="vrt-main-content" v-if="liveSwitchTab">
      <div class="vrt-main-warp">
        <div class="vrt-tab-warp">
          <div class="vrt-main-tab">
            <div class="swiper-container" id="menu-nav-swiper">
              <ul class="swiper-wrapper">
                <li
                  class="swiper-slide"
                  :class="{ current: itemBool == index, 'current-view': index == 1, 'five-dot': index === 2 }"
                  v-for="(item, index) in menu"
                  :key="index"
                  @click="selectMenu(item)"
                >
                  {{ item }}
                </li>
              </ul>
            </div>

          </div>
          <div class="vrt-main-tab-content">
            <div class="vrt-main-tab-first" v-show="itemBool == 0">
              <div class="video-info">
                <template v-if="live.liveStatus == 0">
                  <div
                    class="count_down count_down_time"
                    v-if="intro.liveStartTime"
                    id="dateShow"
                  >
                    <p>直播倒计时</p>
                    <span class="d">07</span>天 <span class="h">22</span>小时
                    <span class="m">50</span>分 <span class="date-s s">56</span>秒
                  </div>
                  <div class="count_down">
                    <span>{{ intro.courseName}}</span>
                  </div>
                </template>
                <template v-if="live.liveStatus == 1">
                  <span class="count_down"><span>直播进行中</span></span>
                  <div class="count_down">
                    <span>{{ intro.courseName}}</span>
                  </div>
                </template>
                <template v-if="live.liveStatus == 2">
                  <div class="count_down count_down_time" id="dateShow">
                    <p>直播倒计时</p>
                    <span class="d"></span>天 <span class="h"></span>小时
                    <span class="m"></span>分 <span class="date-s s"></span>秒
                  </div>
                  <div class="count_down">
                    <span>{{ intro.courseName}}</span>
                  </div>
                </template>
                <template v-if="live.liveStatus == 3">
                  <span class="count_down live-end"><span>直播已结束</span></span>
                  <div class="count_down">
                    <span>{{ intro.courseName}}</span>
                  </div>
                </template>
                <class-intro :intro="intro"></class-intro>
              </div>

              <div class="vrt-first-content">
                <div class="course-description">
                  <class-sch-card :courseChapterList="courseChapterList"></class-sch-card>
                </div>
              </div>
            </div>
            <div class="vrt-main-tab-second" v-show="itemBool == 1">
              <div class="vrt-talk-list">

                <div class="vrt-radio-list">
                  <div class="vrt-radio-icon">
                    <div>
                      <img src="@/assets/img/liveRoom/horn.png" alt="">
                    </div>
                    <span class="vrt-radio-close">×</span>
                  </div>
                  <div class="vrt-radio-content"></div>
                </div>

                <div class="vrt-talk-list-ul vrt-second-list">
                  <ul>
                    <li>
                      <div class="vrt-talk-logo">
                        <img
                          src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/public/picman.jpg?x-oss-process=image/resize,w_200"
                          alt="标准物质网，北京普天同创生物科技，课堂"
                        />
                      </div>
                      <div class="vrt-talk-box">
                        <p class="vrt-talk-info">
                          <span class="talk-vip">讲师</span>
                          <span class="talk-name">讲师</span>
                        </p>
                        <div class="vrt-talk-txt">
                          <p class="vrt-talk-main">您好，请问有什么可以为您解答的？</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="vrt-talk-input-wrap">
                <div class="vrt-input-content">
                  <span class="vrt-input-icon gifticon" @click.stop="openGifticon"></span>
                  <div class="vrt-talk-input-main">
                    <textarea
                      type="text"
                      class="talk-input js-talk-input"
                      maxlength="200"
                      placeholder="我也来参与一下互动..."
                      id="chatipt"
                    ></textarea>
                    <span
                      class="vrt-input-icon faceicon"
                      @click.stop="openFaceicon"
                      id="btn-face"
                    ></span>
                  </div>
                  <span class="vrt-action-img">
                    <input
                      type="file"
                      id="action-img-btn"
                      accept="image/jpeg,image/jpg,image/png,image/gif"
                    />
                  </span>
                  <span class="vrt-input-icon imgicon"></span>
                  <span class="talk-send-btn" id="chatlistbtn" for="all">发送</span>
                </div>
                <div class="vrt-gift-wrap">
                  <div class="vrt-gift-main">
                    <ul>
                      <li
                        data-name="鲜花"
                        data-pic="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/01-flower.png"
                      >
                        <div class="course-gift-img">
                          <img
                            src="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/01-flower.png"
                            alt=""
                          />
                        </div>
                        <div class="course-gift-name">鲜花</div>
                      </li>
                      <li
                        data-name="咖啡"
                        data-pic="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/02-coffee.png"
                      >
                        <div class="course-gift-img">
                          <img
                            src="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/02-coffee.png"
                            alt=""
                          />
                        </div>
                        <div class="course-gift-name">咖啡</div>
                      </li>
                      <li
                        data-name="点赞"
                        data-pic="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/03-good.png"
                      >
                        <div class="course-gift-img">
                          <img
                            src="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/03-good.png"
                            alt=""
                          />
                        </div>
                        <div class="course-gift-name">点赞</div>
                      </li>
                      <li
                        data-name="掌声"
                        data-pic="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/04-applaud.png"
                      >
                        <div class="course-gift-img">
                          <img
                            src="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/04-applaud.png"
                            alt=""
                          />
                        </div>
                        <div class="course-gift-name">掌声</div>
                      </li>
                      <li
                        data-name="666"
                        data-pic="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/05-666.png"
                      >
                        <div class="course-gift-img">
                          <img
                            src="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/05-666.png"
                            alt=""
                          />
                        </div>
                        <div class="course-gift-name">666</div>
                      </li>
                      <li
                        data-name="小星星"
                        data-pic="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/06-star.png"
                      >
                        <div class="course-gift-img">
                          <img
                            src="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/06-star.png"
                            alt=""
                          />
                        </div>
                        <div class="course-gift-name">小星星</div>
                      </li>
                      <li
                        data-name="钻石"
                        data-pic="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/07-diamond.png"
                      >
                        <div class="course-gift-img">
                          <img
                            src="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/07-diamond.png"
                            alt=""
                          />
                        </div>
                        <div class="course-gift-name">钻石</div>
                      </li>
                      <li
                        data-name="跑车"
                        data-pic="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/08-car.png"
                      >
                        <div class="course-gift-img">
                          <img
                            src="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/08-car.png"
                            alt=""
                          />
                        </div>
                        <div class="course-gift-name">跑车</div>
                      </li>
                      <li
                        data-name="火箭"
                        data-pic="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/09-rocket.png"
                      >
                        <div class="course-gift-img">
                          <img
                            src="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/09-rocket.png"
                            alt=""
                          />
                        </div>
                        <div class="course-gift-name">火箭</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            
            
            <div class="vrt-main-tab-fifth" v-show="itemBool == 2 && liveSwitchTab">
              <div class="vrt-notice-list">
                <p class="vrt-notice-publish"></p>
                <div class="vrt-notice-follow">
                  <div class="vrt-notice-pic">
                    <div class="vrt-notice-img">
                      <img src="https://www.gbw114.com/yingzeimg/home/home-ptsph.png" alt="">
                    </div>
                    <div class="vrt-notice-txt">
                      长按二维码<br/>
                      关注普天视频号
                    </div>
                  </div>
                  <div class="vrt-notice-pic">
                    <div class="vrt-notice-img">
                      <img src="https://www.gbw114.com/yingzeimg/home/home-sph.jpg" alt="">
                    </div>
                    <div class="vrt-notice-txt">
                      长按二维码<br/>
                      关注仪器视频号
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="vrt-main-tab-sixth" v-if="itemBool == 3 && liveSwitchTab">
              <video-product-card
                :list="plist"
                :id="intro.id"
                :applyProductOpen="applyProductOpen"
              ></video-product-card>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="vrt-main-content" v-else>
      <div class="vrt-main-warp">
        <div class="vrt-tab-warp">
          <div class="vrt-main-tab">
            <div class="swiper-container" id="menu-nav-swiper">
              <ul class="swiper-wrapper">
                <li
                  style="width: 20%;"
                  class="swiper-slide"
                  :class="{ current: itemBoolBack == index, 'current-view': index == 3 }"
                  v-for="(item, index) in menuBack"
                  :key="index"
                  @click="selectMenuBack(item)"
                >
                  {{ item }}
                </li>
              </ul>
            </div>

          </div>
          <div class="vrt-main-tab-content">
            <div class="vrt-main-tab-first" v-show="itemBoolBack == 0">
              <template v-if="courseChapterList.length>0">
                <div class="video-info">
                  <template v-if="live.liveStatus == 0">
                    <div
                      class="count_down count_down_time"
                      v-if="intro.liveStartTime"
                      id="dateShow"
                    >
                    <p>直播倒计时</p>
                    <span class="d">07</span>天 <span class="h">22</span>小时
                    <span class="m">50</span>分 <span class="date-s s">56</span>秒
                    </div>
                    <div class="count_down">
                      <span>{{ intro.courseName && intro.courseName.substr(0, 38) }}</span>
                    </div>
                  </template>
                  <template v-if="live.liveStatus == 1">
                    <span class="count_down"><span>直播进行中</span></span>
                    <div class="count_down">
                      <span>{{ intro.courseName && intro.courseName.substr(0, 38) }}</span>
                    </div>
                  </template>
                  <template v-if="live.liveStatus == 2">
                    <div class="count_down count_down_time" id="dateShow">
                      <p>直播倒计时</p>
                      <span class="d"></span>天 <span class="h"></span>小时
                      <span class="m"></span>分 <span class="date-s s"></span>秒
                    </div>
                    <div class="count_down">
                      <span>{{ intro.courseName && intro.courseName.substr(0, 38) }}</span>
                    </div>
                  </template>
                  <template v-if="live.liveStatus == 3">
                    <span class="count_down live-end"><span>直播已结束</span></span>
                    <div class="count_down">
                      <span>{{ intro.courseName && intro.courseName.substr(0, 38) }}</span>
                    </div>
                  </template>
                  <class-intro :intro="intro"></class-intro>
                </div>

                <div class="vrt-first-content">
                  <div class="course-description">
                    <class-sch-card :courseChapterList="courseChapterList"></class-sch-card>
                  </div>
                </div>
              </template>
              <div class="empty" v-else>
                <img src="@/assets/img/icon36.png" class="focus_img" />
                <div>
                  暂无信息
                </div>
              </div>
            </div>
            <div class="vrt-main-tab-first" v-show="itemBoolBack == 1">
              <ul class="playback" v-if="RecList.length>0">
                <li class="play-video" v-for="(item, index) in RecList" :key="item.id">
                  <router-link :to="{name: 'videoRealtime', query: { id: intro.id, index: index }}">
                    <img src="@/assets/images/icon-redbf.png" alt />
                    <div class="video-detail">
                      <div class="video-detail-title">{{ item.recordRoomName }}</div>
                      <div class="video-detail-date">{{ dateFormat(item.recordStartTime) }}</div>
                    </div>
                    <class-sch-card :itemBool="itemBoolBack" :courseChapterList="item.courseChapterVOList" :summaryBool="false"></class-sch-card>
                  </router-link>
                </li>
              </ul>
              <div class="empty" v-else>
                <img src="@/assets/img/icon36.png" class="focus_img" />
                <div>
                  暂无信息
                </div>
              </div>
            </div>
            <div class="vrt-main-tab-first" style="padding-bottom: 1.33333rem;" v-show="itemBoolBack == 2" v-if="live.liveStatus!=1">
              <class-comment :listComment="listComment" v-if="listComment.length>0"></class-comment>
              <div class="empty" v-else>
                <img src="@/assets/img/icon36.png" class="focus_img" />
                <div>
                  暂无信息
                </div>
              </div>
              <class-footer :isBack="true" :isPlay="false" v-model="comment" @submit="subComment"></class-footer>
            </div>
            <div class="vrt-main-tab-second" v-show="itemBoolBack == 3">
              <div class="vrt-talk-list">

                <div class="vrt-radio-list">
                  <div class="vrt-radio-icon">
                    <div>
                      <img src="@/assets/img/liveRoom/horn.png" alt="">
                    </div>
                    <span class="vrt-radio-close">×</span>
                  </div>
                  <div class="vrt-radio-content"></div>
                </div>

                <div class="vrt-talk-list-ul vrt-second-list">
                  <ul>
                    <li>
                      <div class="vrt-talk-logo">
                        <img
                          src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/public/picman.jpg?x-oss-process=image/resize,w_200"
                          alt="标准物质网网站，普天同创，课堂"
                        />
                      </div>
                      <div class="vrt-talk-box">
                        <p class="vrt-talk-info">
                          <span class="talk-vip">讲师</span>
                          <span class="talk-name">讲师</span>
                        </p>
                        <div class="vrt-talk-txt">
                          <p class="vrt-talk-main">您好，请问有什么可以为您解答的？</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="vrt-talk-input-wrap">
                <div class="vrt-input-content">
                  <span class="vrt-input-icon gifticon" @click.stop="openGifticon"></span>
                  <div class="vrt-talk-input-main">
                    <textarea
                      type="text"
                      class="talk-input js-talk-input"
                      maxlength="200"
                      placeholder="我也来参与一下互动..."
                      id="chatipt"
                    ></textarea>
                    <span
                      class="vrt-input-icon faceicon"
                      @click.stop="openFaceicon"
                      id="btn-face"
                    ></span>
                  </div>
                  <span class="vrt-action-img">
                    <input
                      type="file"
                      id="action-img-btn"
                      accept="image/jpeg,image/jpg,image/png,image/gif"
                    />
                  </span>
                  <span class="vrt-input-icon imgicon"></span>
                  <span class="talk-send-btn" id="chatlistbtn" for="all">发送</span>
                </div>
                <div class="vrt-gift-wrap">
                  <div class="vrt-gift-main">
                    <ul>
                      <li
                        data-name="鲜花"
                        data-pic="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/01-flower.png"
                      >
                        <div class="course-gift-img">
                          <img
                            src="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/01-flower.png"
                            alt=""
                          />
                        </div>
                        <div class="course-gift-name">鲜花</div>
                      </li>
                      <li
                        data-name="咖啡"
                        data-pic="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/02-coffee.png"
                      >
                        <div class="course-gift-img">
                          <img
                            src="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/02-coffee.png"
                            alt=""
                          />
                        </div>
                        <div class="course-gift-name">咖啡</div>
                      </li>
                      <li
                        data-name="点赞"
                        data-pic="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/03-good.png"
                      >
                        <div class="course-gift-img">
                          <img
                            src="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/03-good.png"
                            alt=""
                          />
                        </div>
                        <div class="course-gift-name">点赞</div>
                      </li>
                      <li
                        data-name="掌声"
                        data-pic="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/04-applaud.png"
                      >
                        <div class="course-gift-img">
                          <img
                            src="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/04-applaud.png"
                            alt=""
                          />
                        </div>
                        <div class="course-gift-name">掌声</div>
                      </li>
                      <li
                        data-name="666"
                        data-pic="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/05-666.png"
                      >
                        <div class="course-gift-img">
                          <img
                            src="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/05-666.png"
                            alt=""
                          />
                        </div>
                        <div class="course-gift-name">666</div>
                      </li>
                      <li
                        data-name="小星星"
                        data-pic="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/06-star.png"
                      >
                        <div class="course-gift-img">
                          <img
                            src="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/06-star.png"
                            alt=""
                          />
                        </div>
                        <div class="course-gift-name">小星星</div>
                      </li>
                      <li
                        data-name="钻石"
                        data-pic="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/07-diamond.png"
                      >
                        <div class="course-gift-img">
                          <img
                            src="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/07-diamond.png"
                            alt=""
                          />
                        </div>
                        <div class="course-gift-name">钻石</div>
                      </li>
                      <li
                        data-name="跑车"
                        data-pic="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/08-car.png"
                      >
                        <div class="course-gift-img">
                          <img
                            src="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/08-car.png"
                            alt=""
                          />
                        </div>
                        <div class="course-gift-name">跑车</div>
                      </li>
                      <li
                        data-name="火箭"
                        data-pic="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/09-rocket.png"
                      >
                        <div class="course-gift-img">
                          <img
                            src="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/09-rocket.png"
                            alt=""
                          />
                        </div>
                        <div class="course-gift-name">火箭</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <input type="hidden" v-model="detail.nickname" id="username" />
  <template v-if="live.liveStatus==1">
    <input type="hidden" v-model="detail.roomId" id="roomId" />
    <input type="hidden" v-model="detail.viewertoken" id="viewertoken" />
  </template>
  <template v-else>
    <input type="hidden" v-model="detail.liveId" id="liveId" />
    <input type="hidden" v-model="detail.recordRoomId" id="recordRoomId" />
    <input type="hidden" v-model="detail.recordId" id="recordId" />
    <input type="hidden" v-model="live.liveStatus" id="liveStatusId" />
    <input type="hidden" v-model="detail.viewertoken" id="viewertoken" />
  </template>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, nextTick, onUnmounted, reactive, watch, inject } from "vue";
import { useRoute } from "vue-router";

import dateFormat, { timeFormat } from "@/utils/dateFormat";
import ClassIntro from "@/components/common/ClassIntro.vue";
import ClassSchCard from "@/components/common/ClassSchCard.vue";
import ClassComment from "@/components/common/ClassComment.vue";
import VideoProductCard from "@/components/common/VideoProductCard.vue";
import api from "@/api/axios";
import axios from "@/api/axios";
import $ from "jquery";
import asyncLoadJs, { removeJs } from "@/hooks/useAsyncLoadJs";
import leftTime, { clearTime } from "@/hooks/useLeftTime";
import wx from "weixin-sdk-js";
import Swiper from "swiper";
import useClipboard from 'vue-clipboard3'
import Toast from "@/components/UI/Toast";
import qs from "qs";
import Modal from "@/components/UI/Modal";
import { useRouter } from "vue-router";
import Clipboard from 'clipboard'
import ClassFooter from "@/components/common/ClassFooter.vue";


export default defineComponent({
  name: "videoRealtime",
  components: {
    ClassIntro,
    ClassSchCard,
    VideoProductCard,
    ClassComment,
    ClassFooter
  },
  setup() {
    const route = useRoute();
    const id = ref(route.query.id);
    const type = route.query.type;
    const vip = route.query.vip
    const intro = ref({});
    const detail = ref({});
    const courseChapterList = ref<object>([]);
    const live = ref({});

    // const plist = reactive<object[]>([]);
    const plist = reactive({data: []});
    const applyProductOpen = ref(false);
    const inviteList = reactive({data: []});
    let timer: any = null;
    let inviteTimer: any = null
    let followTimer: any = null
    const liveRedPacket = ref(false)
    const router = useRouter();
    const liveProductSwitch = ref()
    const liveSwitchTab = ref(true)
    const menu = ref()
    const menuBack = ref()
    const urlData = ref()
    const visible = ref(false)
    const visiblePic = ref(false)
    const uid = ref()
    const codeImg = ref()
    const RecList = ref<object>([]);
    const listComment = ref<object>([]);
    const comment = ref("");
    const errorMsgStr =ref();
    const user = inject("userInfo") as object;

    function getRenderData(id: any) {
      api
      .get("/M/Discover/VideoRealtime/" + id, {
        params: {
          type: type,
          vip: vip
        },
      })
      .then((res) => {
        if (res.data.success == false) {
          if(res.data.msg =='请先登录再执行此操作'){
            Toast({
              type: "error",
              title: res.data.msg,
              onClose: function () {
                router.push({
                  name: "Login",
                  query: {
                    redirect: router.currentRoute.value.fullPath,
                  },
                });
              }
            });
          }else{
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        }else{
          const data = res.data.obj;
          errorMsgStr.value = data.errorMsg;
          if(data.errorMsg=="NO_RECORD"){
            Toast({
              type: "error",
              title: "暂无回放",
            });
          }
          // liveProductSwitch.value = data.liveProductSwitch
          liveProductSwitch.value = data.course.liveType
          if (data.course.liveType == 0) {
            liveSwitchTab.value = false
            if (data.live.liveStatus==1) {
              menuBack.value = ["课程概述", "回放目录(0)", "聊天"];
            } else {
              menuBack.value = ["课程概述", "回放目录(0)", "评论(0)"];
              menuBack.value[2] = "评论(" + data.listComment.length + ")";
            }
          } else {
            liveSwitchTab.value = true
            menu.value = ["介绍", "聊天", "公告", "抢购"];
            // menu.value = ["介绍", "聊天", "公告",];
            menuBack.value = ["课程概述", "回放目录(0)", "评论(0)"];
          }
          intro.value = data.course;
          detail.value = data;
          courseChapterList.value = data.courseChapterList;
          live.value = data.live || '';
          uid.value = data.currentUser && data.currentUser.id;
          RecList.value = data.recList;
          menuBack.value[1] = "回放目录(" + data.recList.length + ")";
          listComment.value = data.listComment;

          nextTick(() => {
            asyncLoadJs("js/jquery-3.1.1.min.js", "videoRealtime").then(() => {
              if (data.live.liveStatus!=1) {
                asyncLoadJs("js/playbackSDK.js", "videoRealtime").then(() => {
                  asyncLoadJs("js/livePlayBack.js", "videoRealtime");
                });
              } else {
                asyncLoadJs("js/base.js", "videoRealtime").then(() => {
                  asyncLoadJs("js/liveSDK.JS", "videoRealtime").then(() => {
                    asyncLoadJs("js/videoRealtime.js", "videoRealtime");
                  });
                });
              }
            });
            leftTime(data.course.liveStartTime, function (d: any) {
              if (d.status) {
                const $dateShow1 = $("#dateShow");
                $dateShow1.find(".d").html(d.d);
                $dateShow1.find(".h").html(d.h);
                $dateShow1.find(".m").html(d.m);
                $dateShow1.find(".s").html(d.s);
              }
            })
          });

        }

        followTimer = setTimeout(() => {
          if (!res.data.obj.currentUser || res.data.obj.currentUser == 'null') {
            // Modal({
            //   title: "温馨提示",
            //   confirmText: '登录',
            //   content: `
            //     <div>完成以下两步，即可参与各项直播福利：</div>
            //     <div style="text-align: left;height: 120px;">
            //       <div style="float: left;width: 50%;">
            //         <div style="height: 50px;margin-bottom: 20px;padding-top: 4px;">
            //           <span style="float: left;height: 100%;">1、</span>
            //           <span>关注（长按）右侧公众号</span></br>
            //         </div>
            //         2、点击右下角登录
            //       </div>
            //       <div style="float: right;width: 50%;">
            //         <img width="120px" src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/Yunan/weiye_erweima20200814.jpg" alt="">
            //       </div>
            //     </div>
            //     `,
            //   dangerouslyUseHTMLString: true,
            //   onConfirm: (tel) => {
            //     router.push({
            //       name: "Login",
            //       query: {
            //         redirect: router.currentRoute.value.fullPath,
            //       },
            //     });
            //   },
            //   onClose: () => {
            //     console.log('1')
            //   },
            // });
          }
        }, 10000)
      })
      .catch((err) => {
        console.log(err);
      });
    }
    getRenderData(id.value)

    function contrast(startDate: string) {
      return new Date().getTime() - new Date(timeFormat(startDate)).getTime() < 0
        ? true
        : false;
    }

    //  切换列表菜单
    // const menu = ref(["直播介绍", "互动聊天", "提问", "邀请栏", "公告", "商品栏"]);
    const itemBool = ref(0);
    const itemBoolBack = ref(0)
    function selectMenu(item: string) {
      if (item == "介绍") {
        itemBool.value = 0;
      }
      if (item.indexOf("聊天") != -1) {
        itemBool.value = 1;
      }
      // if (item == "提问") {
      //   itemBool.value = 2;
      // }
      if (item == "邀请榜") {
        itemBool.value = 4;

        api.post("/M/CourseServer/GetLiveInviteList", qs.stringify({
          id: (intro.value as any).id
        }))
        .then((res) => {
          inviteList.data = res.data.data
        })
        .catch((err) => {
          console.log(err);
        });
        codeImg.value = "/M/Server/createPoster?id=" + (intro.value as any).id+"&uid="+uid.value;
      }
      if (item == "公告") {
        itemBool.value = 2;
        $('.five-dot i').hide()
      }
     if (item == "抢购") {
          itemBool.value = 3;
          api.post("/M/CourseServer/GetLiveProductList", qs.stringify({
            id: (intro.value as any).id
          }))
                  .then((res) => {
                    if(res.data.type == '1'){
                      applyProductOpen.value = true
                    }else{
                      applyProductOpen.value = false
                    }
                    plist.data = res.data.obj
                  })
                  .catch((err) => {
                    console.log(err);
                  });

        }

      // 广播通知位置修改
      if (itemBool.value == 1) {
        if ($(".vrt-radio-list").attr('style') == 'display: block;') {
          nextTick(() => {
            const vrtRadioHeight = $('.vrt-radio-list').outerHeight()
            $('.vrt-talk-list-ul').css('padding-top', ((vrtRadioHeight as any)+10)+'px')
          })
        }
      } else {
        $('.vrt-talk-list-ul').removeAttr('style')
      }
    }
    function selectMenuBack(item: string) {
      if (item == "课程概述") {
        itemBoolBack.value = 0;
      }
      if (item.indexOf("回放目录") != -1) {
        itemBoolBack.value = 1;
      }
      if (item.indexOf("评论") != -1) {
        itemBoolBack.value = 2;
      }
      if (item.indexOf("聊天") != -1) {
        itemBoolBack.value = 3;
      }
    }


    onMounted(() => {
        new Swiper("#menu-nav-swiper", {
          initialSlide: 0,
          slidesPerView: 5,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          on: {
            observerUpdate: function () {
              this.slideTo(0);
            },

          },
          // onSlideChangeEnd: function(){
          //   }
        });

        const isweixin = ref(false);
        const ua = navigator.userAgent.toLowerCase();
        const reg = /MicroMessenger/i;
        isweixin.value = reg.test(ua);
        if (isweixin.value) {
          nextTick(() => {
            // const imgUrl = "http://shijiaoke.oss-cn-beijing.aliyuncs.com/Yunan/logo20200923.svg";
            const link = window.location.href;
            const title = document.title;
            const desc = (document.querySelectorAll(
              "meta[name=description]"
            )[0] as any).content;
            // console.log(desc);

            const formData = new FormData();
            formData.append("url", link);
            axios.post("/M/Server/getweixin", formData).then((res: any) => {
              const data = res.data;
              wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: data.appid, // 必填，公众号的唯一标识
                timestamp: parseInt(data.timestamp), // 必填，生成签名的时间戳
                nonceStr: data.nonceStr, // 必填，生成签名的随机串
                signature: data.str, // 必填，签名
                jsApiList: [
                  "checkJsApi",
                  "updateAppMessageShareData",
                  "updateTimelineShareData",
                ], // 必填，需要使用的JS接口列表
              });
              wx.ready(function () {
                //需在用户可能点击分享按钮前就先调用

                wx.updateAppMessageShareData({
                  title: route.meta.title+'-普天同创', // 分享标题
                  desc: route.meta.content.description, // 分享描述
                  link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: "http://m.gbw114.com/img/logo.png", // 分享图标
                  success: function () {
                    // 设置成功
                  },
                });
                wx.updateTimelineShareData({
                  title: route.meta.title+'-普天同创', // 分享标题
                  desc: route.meta.content.description, // 分享描述
                  link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: "http://m.gbw114.com/img/logo.png", // 分享图标
                  success: function () {
                    // 设置成功
                  },
                });
              });
            });
          });
        }
    })


    onUnmounted(() => {
      removeJs("jquery-3.1.1.min.js")
      removeJs("base.js")
      removeJs("liveSDK.JS")
      removeJs("videoRealtime.js")
      removeJs("playbackSDK.js")
      removeJs("livePlayBack.js");

      removeJs("socket.io.js")
      removeJs("swfobject.js")
      removeJs("json3.min.js")
      removeJs("report.js")
      removeJs("dpc.js")

      // clearTime();
    })


    const { toClipboard } = useClipboard()
    const copy = async (Msg: any) => {
      try {
        //复制
        await toClipboard(Msg)
        // console.log(Msg)
        Toast({
          type: "success",
          title: "复制链接成功，邀请好友进入直播间并登录，视为邀请成功"
        });
      } catch (e) {
        //复制失败
        // console.error(e)
        Toast({
          type: "error",
          title: "链接拷贝失败"
        });
      }
    }
    function clickBtn(){

      const token = localStorage.getItem("token");
      if (!token) {
        Modal({
          title: "温馨提示",
          content: "登录后即可邀请，立刻前往",
          confirmText: '登录',
          onConfirm: () => {
            router.push({
              name: "Login",
              query: {
                  redirect: router.currentRoute.value.fullPath,
              },
            });
          },
        });
        return false;
      } else {
        api.post("/M/CourseServer/CreateLiveInvite", qs.stringify({
          id: (intro.value as any).id
        }))
        .then((res) => {
          if (!res.data.success) {
            Toast({
              type: "error",
              title: res.data.msg
            });
          }
          copy(res.data.obj)
        })
        .catch((err) => {
          console.log(err);
        });
      }
    }
 
    function handleCopy() {
      const token = localStorage.getItem("token");
      if (!token) {
        Modal({
          title: "温馨提示",
          content: "登录后即可邀请，立刻前往",
          confirmText: '登录',
          onConfirm: () => {
            router.push({
              name: "Login",
              query: {
                  redirect: router.currentRoute.value.fullPath,
              },
            });
          },
        });
        return false;
      } else {

        visiblePic.value = true

        api.post("/M/CourseServer/CreateLiveInvite", qs.stringify({
          id: (intro.value as any).id
        }))

        .then((res) => {
        })
        .catch((err) => {
          console.log(err);
        });
      }
    }

    function confirm() {
      const clipboard = new Clipboard('.confirm-copy');
      clipboard.on('success', (e) => {
        Toast({
          type: "success",
          title: "复制链接成功，邀请好友进入直播间并登录，视为邀请成功"
        });
        // 释放内存
        visible.value = false
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        Toast({
          type: "error",
          title: "链接拷贝失败"
        });
        // 释放内存
        clipboard.destroy()
      })
    }
    function close() {
      visible.value = false
    }
    function closePic() {
      visiblePic.value = false
    }



    watch(
      () => {
        return itemBool.value
      },
      (newValue) => {
                // console.log('live.value',(live.value as any).liveStatus);
                // if (newValue == 3) {
                //如果当前直播状态是已结束就，不定时刷新抢购产品
                if (newValue == 3 && (live.value as any).liveStatus != 3) {
                  $('.five-dot i').hide()
                  timer = setInterval(() => {
                    api.post("/M/CourseServer/GetLiveProductList", qs.stringify({
                      id: (intro.value as any).id
                    }))
                            .then((res) => {
                              if(res.data.type == '1'){
                                applyProductOpen.value = true;
                              }else{
                                applyProductOpen.value = false;
                              }
                              for(let i=0;i<plist.data.length;i++) {
                                if (res.data.obj[i].type != (plist.data[i] as any).type) {
                                  (plist.data[i] as any).type = res.data.obj[i].type
                                }
                                if (res.data.obj[i].showStock != (plist.data[i] as any).showStock) {
                                  (plist.data[i] as any).showStock = res.data.obj[i].showStock
                                }
                              }
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                  }, 8000);
                } else {
                  clearInterval(timer)
                  plist.data = []
                }

                if (newValue == 4) {
                  inviteTimer = setInterval(() => {
                    api.post("/M/CourseServer/GetLiveInviteList", qs.stringify({
                      id: (intro.value as any).id
                    }))
                            .then((res) => {
                              inviteList.data = res.data.data
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                  }, 8000);
                } else {
                  clearInterval(inviteTimer)
                  // inviteList.data = []
                }
              }
    )

    // 定时轮训是否显示红包雨
    const redRain = setInterval(() => {
      api.post("/M/CourseServer/GetLiveOpenList", qs.stringify({
        id: (intro.value as any).id
      }))
      .then((res) => {
        if (res.data.obj.liveRedPacket) {
          liveRedPacket.value = true
        } else {
          liveRedPacket.value = false
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }, 10000);

    // 直播跳转红包
    function liveJumpRed() {
      const token = localStorage.getItem("token");
      if (!token) {
        Modal({
          title: "温馨提示",
          content: "登录后可参与，前往登录",
          confirmText: '登录',
          onConfirm: () => {
            router.push({
              name: "Login",
              query: {
                  redirect: router.currentRoute.value.fullPath,
              },
            });
          },
          onClose: () => {
            console.log('1')
          },
        });
        return false;
      }

      const fData = new FormData();
      fData.append("returnurl", 'http://m.gbw114.com/videored?courseId='+(intro.value as any).id);
      api
        .post("/M/Event/redPacketWeChatUrl", fData)
        .then((res) => {
          if (res.data.success) {
            window.location.href = res.data.returnUrl;
          } else {
            Toast({
              title: res.data.msg,
              type: "error",
            });
          }
        })
        .catch(() => {
          console.log('false false')
        });
    }

    watch(
      [
        () => {
          return route.name;
        }
      ],
      (to, from) => {
        if (from && from[0] == 'videoRealtime') {
          clearInterval(inviteTimer)
          clearInterval(timer)
          clearInterval(redRain)
          clearTimeout(followTimer)
        }
      }
    );
    watch(
      () => {
        return liveProductSwitch.value
      },
      (newValue) => {
        if (newValue == 0) {
          clearInterval(inviteTimer)
          clearInterval(timer)
          clearInterval(redRain)
          clearTimeout(followTimer)
        }
      }
    )
    watch(
      () => {
        return menu.value
      },
      () => {
        new Swiper("#menu-nav-swiper", {
          initialSlide: 0,
          slidesPerView: 5,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          on: {
            observerUpdate: function () {
              this.slideTo(0);
            },
          },
        });
      }
    )
    watch(
      () => {
        return menuBack.value
      },
      () => {
        new Swiper("#menu-nav-swiper", {
          initialSlide: 0,
          slidesPerView: 5,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          on: {
            observerUpdate: function () {
              this.slideTo(0);
            },
          },
        });
      }
    )



    function subComment() {
      if (!comment.value) {
        Toast({
          type: "warning",
          title: "请先输入评论再提交哦~",
        });
        return false;
      }
      const data = qs.stringify({
        id,
        content: comment.value,
        type: 0
      });
      axios
        .post("/M/Server/DoCourseComment", data)
        .then((res) => {
          if (res.data.success) {
            Toast({
              type: "success",
              title: res.data.msg,
            });
            (listComment.value as object[]).unshift({
              ...res.data.obj,
              isAlreadyZan: 0,
              user: {
                ...user,
                userImg: (user as any).userImg,
              },
            });
            menuBack.value[2] = "评论(" + (listComment.value as object[]).length + ")";
            comment.value = "";
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
            router.push({name: "Login"})
          }
        })
        .catch((err) => {

          const message = err.message ? err.message : "未知错误，请联系管理员";
          Toast({
            type: "error",
            title: message,
          });
        });
    }

    return {
      contrast,
      intro,
      courseChapterList,
      live,
      menu,
      menuBack,
      selectMenu,
      selectMenuBack,
      itemBool,
      itemBoolBack,
      detail,
      plist,
      applyProductOpen,
      clickBtn,
      liveJumpRed,
      liveRedPacket,
      inviteList,
      liveSwitchTab,
      handleCopy,
      urlData,
      confirm,
      close,
      visible,
      visiblePic,
      codeImg,
      closePic,
      RecList,
      dateFormat,
      listComment,
      comment,
      errorMsgStr,
      subComment
      // openGifticon,
      // openFaceicon
    };
  },
});
</script>

<style src=".././assets/styles/videoRealtime.css"></style>

<style lang="scss" scoped>
  .author {
    padding-left: 62px;
  }
  #dateShow span {
    color: #b9135a;
  }

  // 邀请栏
  .invitation-list {
    padding: 15px 15px 10px 15px;
  }
  .rank-title {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
  }
  .rank-prize {
    font-size: 14px;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .rank-prize span {
    width: 50%;
  }
  .rank-prize span:last-child {
    width: 100%;
  }
  .rank-left {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-right: 10px;
    font-weight: bold;
  }
  .rank-left-img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    position: relative;
    top: -2px;
    img {
      width: 100%;
    }
  }
  .rank-right {
    display: flex;
    font-size: 12px;
    color: #b9135a;
  }
  .rank-right>div {
    width: 14px;
    height: 14px;
    img {
      width: 100%;
      margin-right: 5px;
    }
  }
  .rank-right > span {
    margin-left: 5px;
  }


  .rank-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
  }
  .rank-list {
    display: flex;
    align-items: center;
  }
  .rank-first {
    width: 20px;
    height: 20px;
    line-height: 23px;
    text-align: center;
    margin-right: 10px;
    img {
      position: relative;
      // top: 3px;
      width: 100%;
    }
  }
  .rank-second {
    width: 35px;
    height: 35px;
    margin-right: 10px;
    img {
      border-radius: 50%;
      width: 100%;
    }
  }

// 邀请栏
.vrt-main-tab-fourth {
  height: 100%;
  overflow: auto;
}

 /* 公告 */
.vrt-main-tab-fifth {
  background: url("../assets/img/liveRoom/notice.png") no-repeat;
  background-position: 100% 130%;
  background-size: contain;
}

// 公告
.vrt-notice-list {
  padding: 15px 15px 10px 15px;
  height: 100%;
  overflow: auto;
  font-size: 14px;
}
.vrt-notice-list p {
  line-height: 25px;
  text-indent: 8px;
  color: #393939;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}
.vrt-notice-follow {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
  padding-bottom: 70px;
}
.vrt-notice-pic {
  background: #00B278;
}
.vrt-notice-img {
  // width: 160px;
  // height: 160px;
  width: 112px;
  height: 112px;
  padding: 5px;
  img {
    width: 100%;
  }
}
.vrt-notice-txt {
  font-size: 14px;
  color: #fff;
  text-align: center;
  height: 45px;
  // height: 25px;
  // line-height: 22px;
}

// 商品栏
.vrt-main-tab-sixth {
  height: 100%;
  overflow: auto;
}


// 广播、通知
.vrt-radio-list {
  position: absolute;
  top: 0;
  background: #FFF7E9;
  width: 100%;
  padding: 10px;
  padding-top: 0;
  font-size: 14px;
  z-index: 9;
  display: none;
}
.vrt-radio-content {
  color: #F99136;
}
.vrt-radio-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.vrt-radio-icon > div {
  width: 20px;
  height: 20px;
  img {
    width: 100%;
  }
}
.vrt-radio-icon > span {
  font-size: 25px;
  font-weight: bold;
  position: relative;
  top: 1px;
}


// 抽奖
.luck-draw {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  .toast {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
  }
  .red-package-content {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    img{
      width: 100%;
    }
    .close{
      width: 32px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(400%);
      z-index: 100;
    }
  }
}

.lottery {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999999;
    left: 10%;
    // display: none;
}

.lotterydiv {
    background: #fffcdc url('../assets/img/liveRoom/lottery04.gif') center -46px no-repeat;
    // background-size: 140%;
    background-size: 150%;
    background: #fffcdc url('../assets/img/liveRoom/lottery05.gif') center -46px no-repeat \9;
    border: 1px solid #ff512c;
    z-index: 999;
    box-shadow: inset 0px -4px 0px 0px #ff512c;
    // width: 270px;
    width: 200px;
    // height: 190px;
    height: 150px;
    border-radius: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -95px 0 0 -135px;
}

.lotteryh3 {
    background: url('../assets/img/liveRoom/lottery01.png') no-repeat;
    text-align: center;
    line-height: 50px;
    color: #FFF;
    font-size: 18px;
    // width: 325px;
    width: 255px;
    height: 50px;
    position: absolute;
    background-size: 100%;
    background-image: none \9;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../assets/img/liveRoom/lottery01.png', sizingMethod='scale') \9;
    top: -24px;
    left: -28px;
}

.lotteryclose {
    background: url('../assets/img/liveRoom/lottery02.png') no-repeat;
    width: 30px;
    height: 30px;
    background-size: 100%;
    background-image: none \9;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../assets/img/liveRoom/lottery02.png', sizingMethod='scale') \9;
    position: absolute;
    top: -13px;
    right: 6px;
    cursor: pointer;
}

.lotterybox {
    position: absolute;
    top: 39px;
    // top: 62px;
    width: 100%;
    // height: 110px;
    height: 100px;
    background: #fffcdc;
    display: none;
}

.lotterytext2 {
    font-size: 14px;
    color: #666666;
    line-height: 28px;
    text-align: center;
}

.lotteryname {
    font-size: 21px;
    color: #ff512c;
    line-height: 42px;
    text-align: center;
    // width: 200px;
    margin: 0 auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

// 公告、广播变更显示通知
.five-dot {
  position: relative;
}


// 复制链接
.toast-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed;
  z-index: 101;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  color: #333;
  padding: 10px 20px;
  min-height: 50px;
  max-width: 340px;
  width: 80%;
  font-size: 14px;
  box-sizing: border-box;
  .modal-header {
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    text-align: center;
  }
  .modal-content {
    padding: 15px 0;
    line-height: 24px;
    text-align: center;
  }
  .modal-footer {
    display: flex;
    line-height: 40px;
    div {
      flex: 1;
      text-align: center;

      background-color: #d64b4b;
      border-radius: 5px;
      color: #fff;
    }
    .confirm {
      color: #b9135a;
    }
  }
  img {
    width: 30px;
    height: 30px;

    margin: 0 auto;
    margin-bottom: 10px;
    display: block;
  }
}
.modal-input input {
  border: none;
  line-height: 44px;
  width: 100%;
  display: block;
  margin: 5px;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding-left: 5px;
  box-sizing: border-box;
}


.playback-author-warp {
    padding: 20px 10px 50px 10px;
}
.playback li {
    position: relative;

    border-bottom: 1px solid #eeeeee;
    padding: 0.467rem 0.267rem 0.533rem 0.267rem;
    padding-left: 1.6rem;
    box-sizing: border-box;
    overflow: hidden;

}
.playback li > a > img {
    width: 0.933rem;
    height: 0.933rem;
    position: absolute;
    left: 0.267rem;
    top: 0.56rem;
}
.video-detail-title {
    color: #444444;
    font-size: 0.426rem;
    /*width: 6rem;*/
}
.video-detail-date {
    width: 6rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #999999;
    font-size: 0.373rem;
    margin-top: 0.267rem;
}

.empty {
  padding-top: 100px;
  text-align: center;
  padding-bottom: 50px;

  color: #9f9f9f;
  font-size: 13px;
}
.empty img {
  width: 132px;
  height: 104px;
  display: block;
  margin: 0 auto;
  margin-bottom: 15px;
}
.empty a {
  font-size: 13px;
  color: #b9135a;
}

</style>
<style>
.five-dot i {
  position: absolute;
  width: 8px;
  height: 8px;
  background: #b9135a;
  display: inline-block;
  left: 58px;
  top: 3px;
  border-radius: 50%;
  display: none;
}
</style>



