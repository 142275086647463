import { render } from "./VideoRealtime.vue?vue&type=template&id=5181cc40&scoped=true"
import script from "./VideoRealtime.vue?vue&type=script&lang=ts"
export * from "./VideoRealtime.vue?vue&type=script&lang=ts"

import ".././assets/styles/videoRealtime.css?vue&type=style&index=0&lang=css"
import "./VideoRealtime.vue?vue&type=style&index=1&id=5181cc40&lang=scss&scoped=true"
import "./VideoRealtime.vue?vue&type=style&index=2&id=5181cc40&lang=css"
script.render = render
script.__scopeId = "data-v-5181cc40"

export default script