
import { defineComponent, ref, onMounted, nextTick, onUnmounted, reactive, watch, inject } from "vue";
import { useRoute } from "vue-router";

import dateFormat, { timeFormat } from "@/utils/dateFormat";
import ClassIntro from "@/components/common/ClassIntro.vue";
import ClassSchCard from "@/components/common/ClassSchCard.vue";
import ClassComment from "@/components/common/ClassComment.vue";
import VideoProductCard from "@/components/common/VideoProductCard.vue";
import api from "@/api/axios";
import axios from "@/api/axios";
import $ from "jquery";
import asyncLoadJs, { removeJs } from "@/hooks/useAsyncLoadJs";
import leftTime, { clearTime } from "@/hooks/useLeftTime";
import wx from "weixin-sdk-js";
import Swiper from "swiper";
import useClipboard from 'vue-clipboard3'
import Toast from "@/components/UI/Toast";
import qs from "qs";
import Modal from "@/components/UI/Modal";
import { useRouter } from "vue-router";
import Clipboard from 'clipboard'
import ClassFooter from "@/components/common/ClassFooter.vue";


export default defineComponent({
  name: "videoRealtime",
  components: {
    ClassIntro,
    ClassSchCard,
    VideoProductCard,
    ClassComment,
    ClassFooter
  },
  setup() {
    const route = useRoute();
    const id = ref(route.query.id);
    const type = route.query.type;
    const vip = route.query.vip
    const intro = ref({});
    const detail = ref({});
    const courseChapterList = ref<object>([]);
    const live = ref({});

    // const plist = reactive<object[]>([]);
    const plist = reactive({data: []});
    const applyProductOpen = ref(false);
    const inviteList = reactive({data: []});
    let timer: any = null;
    let inviteTimer: any = null
    let followTimer: any = null
    const liveRedPacket = ref(false)
    const router = useRouter();
    const liveProductSwitch = ref()
    const liveSwitchTab = ref(true)
    const menu = ref()
    const menuBack = ref()
    const urlData = ref()
    const visible = ref(false)
    const visiblePic = ref(false)
    const uid = ref()
    const codeImg = ref()
    const RecList = ref<object>([]);
    const listComment = ref<object>([]);
    const comment = ref("");
    const errorMsgStr =ref();
    const user = inject("userInfo") as object;

    function getRenderData(id: any) {
      api
      .get("/M/Discover/VideoRealtime/" + id, {
        params: {
          type: type,
          vip: vip
        },
      })
      .then((res) => {
        if (res.data.success == false) {
          if(res.data.msg =='请先登录再执行此操作'){
            Toast({
              type: "error",
              title: res.data.msg,
              onClose: function () {
                router.push({
                  name: "Login",
                  query: {
                    redirect: router.currentRoute.value.fullPath,
                  },
                });
              }
            });
          }else{
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        }else{
          const data = res.data.obj;
          errorMsgStr.value = data.errorMsg;
          if(data.errorMsg=="NO_RECORD"){
            Toast({
              type: "error",
              title: "暂无回放",
            });
          }
          // liveProductSwitch.value = data.liveProductSwitch
          liveProductSwitch.value = data.course.liveType
          if (data.course.liveType == 0) {
            liveSwitchTab.value = false
            if (data.live.liveStatus==1) {
              menuBack.value = ["课程概述", "回放目录(0)", "聊天"];
            } else {
              menuBack.value = ["课程概述", "回放目录(0)", "评论(0)"];
              menuBack.value[2] = "评论(" + data.listComment.length + ")";
            }
          } else {
            liveSwitchTab.value = true
            menu.value = ["介绍", "聊天", "公告", "抢购"];
            // menu.value = ["介绍", "聊天", "公告",];
            menuBack.value = ["课程概述", "回放目录(0)", "评论(0)"];
          }
          intro.value = data.course;
          detail.value = data;
          courseChapterList.value = data.courseChapterList;
          live.value = data.live || '';
          uid.value = data.currentUser && data.currentUser.id;
          RecList.value = data.recList;
          menuBack.value[1] = "回放目录(" + data.recList.length + ")";
          listComment.value = data.listComment;

          nextTick(() => {
            asyncLoadJs("js/jquery-3.1.1.min.js", "videoRealtime").then(() => {
              if (data.live.liveStatus!=1) {
                asyncLoadJs("js/playbackSDK.js", "videoRealtime").then(() => {
                  asyncLoadJs("js/livePlayBack.js", "videoRealtime");
                });
              } else {
                asyncLoadJs("js/base.js", "videoRealtime").then(() => {
                  asyncLoadJs("js/liveSDK.JS", "videoRealtime").then(() => {
                    asyncLoadJs("js/videoRealtime.js", "videoRealtime");
                  });
                });
              }
            });
            leftTime(data.course.liveStartTime, function (d: any) {
              if (d.status) {
                const $dateShow1 = $("#dateShow");
                $dateShow1.find(".d").html(d.d);
                $dateShow1.find(".h").html(d.h);
                $dateShow1.find(".m").html(d.m);
                $dateShow1.find(".s").html(d.s);
              }
            })
          });

        }

        followTimer = setTimeout(() => {
          if (!res.data.obj.currentUser || res.data.obj.currentUser == 'null') {
            // Modal({
            //   title: "温馨提示",
            //   confirmText: '登录',
            //   content: `
            //     <div>完成以下两步，即可参与各项直播福利：</div>
            //     <div style="text-align: left;height: 120px;">
            //       <div style="float: left;width: 50%;">
            //         <div style="height: 50px;margin-bottom: 20px;padding-top: 4px;">
            //           <span style="float: left;height: 100%;">1、</span>
            //           <span>关注（长按）右侧公众号</span></br>
            //         </div>
            //         2、点击右下角登录
            //       </div>
            //       <div style="float: right;width: 50%;">
            //         <img width="120px" src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/Yunan/weiye_erweima20200814.jpg" alt="">
            //       </div>
            //     </div>
            //     `,
            //   dangerouslyUseHTMLString: true,
            //   onConfirm: (tel) => {
            //     router.push({
            //       name: "Login",
            //       query: {
            //         redirect: router.currentRoute.value.fullPath,
            //       },
            //     });
            //   },
            //   onClose: () => {
            //     console.log('1')
            //   },
            // });
          }
        }, 10000)
      })
      .catch((err) => {
        console.log(err);
      });
    }
    getRenderData(id.value)

    function contrast(startDate: string) {
      return new Date().getTime() - new Date(timeFormat(startDate)).getTime() < 0
        ? true
        : false;
    }

    //  切换列表菜单
    // const menu = ref(["直播介绍", "互动聊天", "提问", "邀请栏", "公告", "商品栏"]);
    const itemBool = ref(0);
    const itemBoolBack = ref(0)
    function selectMenu(item: string) {
      if (item == "介绍") {
        itemBool.value = 0;
      }
      if (item.indexOf("聊天") != -1) {
        itemBool.value = 1;
      }
      // if (item == "提问") {
      //   itemBool.value = 2;
      // }
      if (item == "邀请榜") {
        itemBool.value = 4;

        api.post("/M/CourseServer/GetLiveInviteList", qs.stringify({
          id: (intro.value as any).id
        }))
        .then((res) => {
          inviteList.data = res.data.data
        })
        .catch((err) => {
          console.log(err);
        });
        codeImg.value = "/M/Server/createPoster?id=" + (intro.value as any).id+"&uid="+uid.value;
      }
      if (item == "公告") {
        itemBool.value = 2;
        $('.five-dot i').hide()
      }
     if (item == "抢购") {
          itemBool.value = 3;
          api.post("/M/CourseServer/GetLiveProductList", qs.stringify({
            id: (intro.value as any).id
          }))
                  .then((res) => {
                    if(res.data.type == '1'){
                      applyProductOpen.value = true
                    }else{
                      applyProductOpen.value = false
                    }
                    plist.data = res.data.obj
                  })
                  .catch((err) => {
                    console.log(err);
                  });

        }

      // 广播通知位置修改
      if (itemBool.value == 1) {
        if ($(".vrt-radio-list").attr('style') == 'display: block;') {
          nextTick(() => {
            const vrtRadioHeight = $('.vrt-radio-list').outerHeight()
            $('.vrt-talk-list-ul').css('padding-top', ((vrtRadioHeight as any)+10)+'px')
          })
        }
      } else {
        $('.vrt-talk-list-ul').removeAttr('style')
      }
    }
    function selectMenuBack(item: string) {
      if (item == "课程概述") {
        itemBoolBack.value = 0;
      }
      if (item.indexOf("回放目录") != -1) {
        itemBoolBack.value = 1;
      }
      if (item.indexOf("评论") != -1) {
        itemBoolBack.value = 2;
      }
      if (item.indexOf("聊天") != -1) {
        itemBoolBack.value = 3;
      }
    }


    onMounted(() => {
        new Swiper("#menu-nav-swiper", {
          initialSlide: 0,
          slidesPerView: 5,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          on: {
            observerUpdate: function () {
              this.slideTo(0);
            },

          },
          // onSlideChangeEnd: function(){
          //   }
        });

        const isweixin = ref(false);
        const ua = navigator.userAgent.toLowerCase();
        const reg = /MicroMessenger/i;
        isweixin.value = reg.test(ua);
        if (isweixin.value) {
          nextTick(() => {
            // const imgUrl = "http://shijiaoke.oss-cn-beijing.aliyuncs.com/Yunan/logo20200923.svg";
            const link = window.location.href;
            const title = document.title;
            const desc = (document.querySelectorAll(
              "meta[name=description]"
            )[0] as any).content;
            // console.log(desc);

            const formData = new FormData();
            formData.append("url", link);
            axios.post("/M/Server/getweixin", formData).then((res: any) => {
              const data = res.data;
              wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: data.appid, // 必填，公众号的唯一标识
                timestamp: parseInt(data.timestamp), // 必填，生成签名的时间戳
                nonceStr: data.nonceStr, // 必填，生成签名的随机串
                signature: data.str, // 必填，签名
                jsApiList: [
                  "checkJsApi",
                  "updateAppMessageShareData",
                  "updateTimelineShareData",
                ], // 必填，需要使用的JS接口列表
              });
              wx.ready(function () {
                //需在用户可能点击分享按钮前就先调用

                wx.updateAppMessageShareData({
                  title: route.meta.title+'-普天同创', // 分享标题
                  desc: route.meta.content.description, // 分享描述
                  link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: "http://m.gbw114.com/img/logo.png", // 分享图标
                  success: function () {
                    // 设置成功
                  },
                });
                wx.updateTimelineShareData({
                  title: route.meta.title+'-普天同创', // 分享标题
                  desc: route.meta.content.description, // 分享描述
                  link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: "http://m.gbw114.com/img/logo.png", // 分享图标
                  success: function () {
                    // 设置成功
                  },
                });
              });
            });
          });
        }
    })


    onUnmounted(() => {
      removeJs("jquery-3.1.1.min.js")
      removeJs("base.js")
      removeJs("liveSDK.JS")
      removeJs("videoRealtime.js")
      removeJs("playbackSDK.js")
      removeJs("livePlayBack.js");

      removeJs("socket.io.js")
      removeJs("swfobject.js")
      removeJs("json3.min.js")
      removeJs("report.js")
      removeJs("dpc.js")

      // clearTime();
    })


    const { toClipboard } = useClipboard()
    const copy = async (Msg: any) => {
      try {
        //复制
        await toClipboard(Msg)
        // console.log(Msg)
        Toast({
          type: "success",
          title: "复制链接成功，邀请好友进入直播间并登录，视为邀请成功"
        });
      } catch (e) {
        //复制失败
        // console.error(e)
        Toast({
          type: "error",
          title: "链接拷贝失败"
        });
      }
    }
    function clickBtn(){

      const token = localStorage.getItem("token");
      if (!token) {
        Modal({
          title: "温馨提示",
          content: "登录后即可邀请，立刻前往",
          confirmText: '登录',
          onConfirm: () => {
            router.push({
              name: "Login",
              query: {
                  redirect: router.currentRoute.value.fullPath,
              },
            });
          },
        });
        return false;
      } else {
        api.post("/M/CourseServer/CreateLiveInvite", qs.stringify({
          id: (intro.value as any).id
        }))
        .then((res) => {
          if (!res.data.success) {
            Toast({
              type: "error",
              title: res.data.msg
            });
          }
          copy(res.data.obj)
        })
        .catch((err) => {
          console.log(err);
        });
      }
    }
 
    function handleCopy() {
      const token = localStorage.getItem("token");
      if (!token) {
        Modal({
          title: "温馨提示",
          content: "登录后即可邀请，立刻前往",
          confirmText: '登录',
          onConfirm: () => {
            router.push({
              name: "Login",
              query: {
                  redirect: router.currentRoute.value.fullPath,
              },
            });
          },
        });
        return false;
      } else {

        visiblePic.value = true

        api.post("/M/CourseServer/CreateLiveInvite", qs.stringify({
          id: (intro.value as any).id
        }))

        .then((res) => {
        })
        .catch((err) => {
          console.log(err);
        });
      }
    }

    function confirm() {
      const clipboard = new Clipboard('.confirm-copy');
      clipboard.on('success', (e) => {
        Toast({
          type: "success",
          title: "复制链接成功，邀请好友进入直播间并登录，视为邀请成功"
        });
        // 释放内存
        visible.value = false
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        Toast({
          type: "error",
          title: "链接拷贝失败"
        });
        // 释放内存
        clipboard.destroy()
      })
    }
    function close() {
      visible.value = false
    }
    function closePic() {
      visiblePic.value = false
    }



    watch(
      () => {
        return itemBool.value
      },
      (newValue) => {
                // console.log('live.value',(live.value as any).liveStatus);
                // if (newValue == 3) {
                //如果当前直播状态是已结束就，不定时刷新抢购产品
                if (newValue == 3 && (live.value as any).liveStatus != 3) {
                  $('.five-dot i').hide()
                  timer = setInterval(() => {
                    api.post("/M/CourseServer/GetLiveProductList", qs.stringify({
                      id: (intro.value as any).id
                    }))
                            .then((res) => {
                              if(res.data.type == '1'){
                                applyProductOpen.value = true;
                              }else{
                                applyProductOpen.value = false;
                              }
                              for(let i=0;i<plist.data.length;i++) {
                                if (res.data.obj[i].type != (plist.data[i] as any).type) {
                                  (plist.data[i] as any).type = res.data.obj[i].type
                                }
                                if (res.data.obj[i].showStock != (plist.data[i] as any).showStock) {
                                  (plist.data[i] as any).showStock = res.data.obj[i].showStock
                                }
                              }
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                  }, 8000);
                } else {
                  clearInterval(timer)
                  plist.data = []
                }

                if (newValue == 4) {
                  inviteTimer = setInterval(() => {
                    api.post("/M/CourseServer/GetLiveInviteList", qs.stringify({
                      id: (intro.value as any).id
                    }))
                            .then((res) => {
                              inviteList.data = res.data.data
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                  }, 8000);
                } else {
                  clearInterval(inviteTimer)
                  // inviteList.data = []
                }
              }
    )

    // 定时轮训是否显示红包雨
    const redRain = setInterval(() => {
      api.post("/M/CourseServer/GetLiveOpenList", qs.stringify({
        id: (intro.value as any).id
      }))
      .then((res) => {
        if (res.data.obj.liveRedPacket) {
          liveRedPacket.value = true
        } else {
          liveRedPacket.value = false
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }, 10000);

    // 直播跳转红包
    function liveJumpRed() {
      const token = localStorage.getItem("token");
      if (!token) {
        Modal({
          title: "温馨提示",
          content: "登录后可参与，前往登录",
          confirmText: '登录',
          onConfirm: () => {
            router.push({
              name: "Login",
              query: {
                  redirect: router.currentRoute.value.fullPath,
              },
            });
          },
          onClose: () => {
            console.log('1')
          },
        });
        return false;
      }

      const fData = new FormData();
      fData.append("returnurl", 'http://m.gbw114.com/videored?courseId='+(intro.value as any).id);
      api
        .post("/M/Event/redPacketWeChatUrl", fData)
        .then((res) => {
          if (res.data.success) {
            window.location.href = res.data.returnUrl;
          } else {
            Toast({
              title: res.data.msg,
              type: "error",
            });
          }
        })
        .catch(() => {
          console.log('false false')
        });
    }

    watch(
      [
        () => {
          return route.name;
        }
      ],
      (to, from) => {
        if (from && from[0] == 'videoRealtime') {
          clearInterval(inviteTimer)
          clearInterval(timer)
          clearInterval(redRain)
          clearTimeout(followTimer)
        }
      }
    );
    watch(
      () => {
        return liveProductSwitch.value
      },
      (newValue) => {
        if (newValue == 0) {
          clearInterval(inviteTimer)
          clearInterval(timer)
          clearInterval(redRain)
          clearTimeout(followTimer)
        }
      }
    )
    watch(
      () => {
        return menu.value
      },
      () => {
        new Swiper("#menu-nav-swiper", {
          initialSlide: 0,
          slidesPerView: 5,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          on: {
            observerUpdate: function () {
              this.slideTo(0);
            },
          },
        });
      }
    )
    watch(
      () => {
        return menuBack.value
      },
      () => {
        new Swiper("#menu-nav-swiper", {
          initialSlide: 0,
          slidesPerView: 5,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          on: {
            observerUpdate: function () {
              this.slideTo(0);
            },
          },
        });
      }
    )



    function subComment() {
      if (!comment.value) {
        Toast({
          type: "warning",
          title: "请先输入评论再提交哦~",
        });
        return false;
      }
      const data = qs.stringify({
        id,
        content: comment.value,
        type: 0
      });
      axios
        .post("/M/Server/DoCourseComment", data)
        .then((res) => {
          if (res.data.success) {
            Toast({
              type: "success",
              title: res.data.msg,
            });
            (listComment.value as object[]).unshift({
              ...res.data.obj,
              isAlreadyZan: 0,
              user: {
                ...user,
                userImg: (user as any).userImg,
              },
            });
            menuBack.value[2] = "评论(" + (listComment.value as object[]).length + ")";
            comment.value = "";
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
            router.push({name: "Login"})
          }
        })
        .catch((err) => {

          const message = err.message ? err.message : "未知错误，请联系管理员";
          Toast({
            type: "error",
            title: message,
          });
        });
    }

    return {
      contrast,
      intro,
      courseChapterList,
      live,
      menu,
      menuBack,
      selectMenu,
      selectMenuBack,
      itemBool,
      itemBoolBack,
      detail,
      plist,
      applyProductOpen,
      clickBtn,
      liveJumpRed,
      liveRedPacket,
      inviteList,
      liveSwitchTab,
      handleCopy,
      urlData,
      confirm,
      close,
      visible,
      visiblePic,
      codeImg,
      closePic,
      RecList,
      dateFormat,
      listComment,
      comment,
      errorMsgStr,
      subComment
      // openGifticon,
      // openFaceicon
    };
  },
});
