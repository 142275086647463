<template>
    <div class="product-list">
        <transition-group tag="ul" name="list">
            <li class="product-li" v-for="(item, index) in list.data" :key="item">
                <div style="padding: 15px 10.012px;">
                    <!--标物-->
                    <div class="product-img" v-if="item.tid==0">
                        <router-link :to="{ name: 'productdetail', params: { id: item.pid, classId: 0} }">
                            <img v-if="item.tid==0" :src="item.picUrl || 'https://yingzegbw.oss-cn-beijing.aliyuncs.com/product/%E4%BA%A7%E5%93%81.jpg'"/>
                        </router-link>
                        <span class="serial-num">{{index+1}}</span>
                    </div>
                    <!--优惠券-->
                    <div class="product-img" v-else-if="item.tid ==88">
                        <img  :src="item.picUrl"/>
                        <span class="serial-num">{{index+1}}</span>
                    </div>
                     <!--仪器产品-->
                    <div class="product-img" v-else-if="item.tid ==33">
                      <router-link :to="{ name: 'instrumentdetail', params: { id: item.pid} }">
                        <img  :src="item.picUrl"/>
                       </router-link>
                        <span class="serial-num">{{index+1}}</span>
                    </div>
                    <!--菌种细胞-->
                    <div class="product-img" v-else>
                        <router-link :to="{ name: 'bnccdetail', params: { id: item.pid, classId: 0} }">
                            <img 
                                 :src="item.picUrl || 'https://yingzegbw.oss-cn-beijing.aliyuncs.com/product/%E4%BA%A7%E5%93%81.jpg'"/>
                        </router-link>
                        <span class="serial-num">{{index+1}}</span>
                    </div>

                    <div class="product-info-wrap">
                        <div class="product-title"  v-if="item.tid==0">
                            <router-link :to="{ name: 'productdetail', params: { id: item.pid, classId: 0} }">
                                <span v-html="item.productName"></span>
                            </router-link>
                            <template v-if="item.type == 0">
                            </template>
                            <template v-else>
                            </template>
                        </div>
                        <div class="product-title"  v-else-if="item.tid ==88">
                            <span v-html="item.productName"></span>
                            <template v-if="item.type == 0">
                            </template>
                            <template v-else>
                            </template>
                        </div>
                        <div class="product-title"  v-else-if="item.tid ==33">
                            <router-link :to="{ name: 'instrumentdetail', params: { id: item.pid} }">
                                <span v-html="item.productName"></span>
                            </router-link>
                            <template v-if="item.type == 0">
                            </template>
                            <template v-else>
                            </template>
                        </div>
                        <div class="product-title" v-else>
                            <router-link :to="{ name: 'bnccdetail', params: { id: item.pid, classId: 0} }">
                                <span v-html="item.productName"></span>
                            </router-link>
                            <template v-if="item.type == 0">
                            </template>
                            <template v-else>
                            </template>
                        </div>
                        <div class="product-card-info" style="position: relative;overflow: visible;width: 100%;">

                            <span v-if="item.tid != 88 && item.tid != 33" v-html="item.catno"></span>
                            <span v-else-if="item.tid == 33" v-html="item.spec"></span>
                            <span v-else>满{{item.nongdu}}可用</span>

                            <span class="wy-brand" v-if="item.tid==33">普量天铸</span>
                            <span class="wy-brand" v-else>普天同创</span>
                            <!-- 立即抢购 -->
                            <template v-if="item.type == 0">
                                <span class="now-buy" style="background: #999;" @click="unableShop">未开启</span>
                            </template>
                            <template v-else>
                                <span v-if="item.showStock>0" class="now-buy" @click.prevent="addShopCar(id, item.id)">立即抢购</span>
                                <span v-else class="now-buy" style="background: #999;"
                                      @click.prevent="addShopCarOver(id, item.id)">已抢完</span>
                            </template>
                        </div>

                        <div class="product-card-brand" v-if="item.tid != 88">
                              <span v-if="item.spec">
                                {{ item.spec }}<template v-if="item.nongdu">;</template> {{ item.nongdu }}
                              </span>
                            <span v-else>{{ item.nongdu }}</span>
                        </div>
                        <div class="product-card-brand" v-else>
                              <span v-if="item.spec">
                                有效期{{ item.spec }}天
                              </span>
                        </div>

                        <div class="product-card-brand">
                            <span class="price">￥{{ item.livePrice }}</span>
                            <span class="market-price">￥{{ item.outPrice }}</span>
                            <span style="margin-left:0.5rem;">库存:{{item.showStock}}</span>
                            <span class="shenq" v-if="applyProductOpen && item.tid !=88 && item.type != 0 && item.showStock<1 "
                                  @click.prevent="addApply(id, item.id)">申请试用</span>
                        </div>
                    </div>
                </div>
                <div class="product-desc" v-show="item.productDesc">
                    <table v-if="item.tid == 0" border="1" cellspacing="0" cellpadding="10.013px" width="100%">
                        <thead>
                        <tr>
                            <th>用途</th>
                            <th>形态</th>
                            <th>基质</th>
                            <th>有效期</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{{item.applications}}</td>
                            <td>{{item.statu}}</td>
                            <td>{{item.stroma}}</td>
                            <td>{{item.shelfLife}}</td>
                        </tr>
                        </tbody>
                    </table>
                    <table v-else border="1" cellspacing="0" cellpadding="10.013px" width="100%" class="bncc-desc">
                        <tbody>
                        <tr>
                            <td class="bncc-name">种属</td>
                            <td>{{item.speciesGenus}}</td>
                        </tr>
                        <tr>
                            <td class="bncc-name">生长条件</td>
                            <td>{{item.growthConditions}}</td>
                        </tr>
                        <tr>
                            <td class="bncc-name">存储条件</td>
                            <td>{{item.storageConditions}}</td>
                        </tr>
                        </tbody>
                    </table>

                </div>
            </li>
        </transition-group>
        <transition-group tag="ul" v-if="false" name="list">
            <li class="product-li skeleton" v-for="item in 10" :key="item">
                <div class="product-img skeleton-bg"></div>
                <div class="product-info-wrap">
                    <div class="product-title skeleton-bg"></div>
                    <div class="product-card-info skeleton-bg"></div>
                    <div class="product-card-brand skeleton-bg"></div>
                </div>
            </li>
        </transition-group>
    </div>
    <div class="modal1" id="modal1" v-if="showModal">
        <div class="modal-backdrop"></div>
        <div class="modal-body">
            <img src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/faa59b1650dbacb64250045ea371df39.png"
                 class="modal-close" @click="closeModal()">
            <p class="modal-rushMessage">
                {{rushMessage}}
            </p>
            <ul v-if="showRush">
                <li v-for="(item,index) in rushList" :key="index">恭喜 <span>{{item.userName}}</span>
                    抢到{{item.productName}}
                </li>
            </ul>
            <ul v-else>
                <li>暂无抢购记录</li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, ref, isReactive, reactive, inject, watch} from "vue";
    import api from "@/api/axios";
    import Toast from "@/components/UI/Toast/";
    import useShopCar from "@/hooks/useShopCar";
    import {useRouter} from "vue-router";
    import qs from "qs";
    import Modal from "@/components/UI/Modal";

    export default defineComponent({
        data() {
            return {
                rushMessage: '',
                rushList: [],
                showRush: true
            }
        },
        props: {
            list: Object,
            id: {
                type: String,
                default: "",
            },
          applyProductOpen: {
            type: Boolean,
            default: false,
          },
        },
        setup(props) {
            const showModal = ref(false);
            const userInfo = inject("userInfo") as any;
            const router = useRouter();

            function closeModal() {
                showModal.value = false
            }

            function addShopCarOver(id: string, livePid: string) {
                this.rushMessage = '已经被抢完了，下次请快点哦';
                      showModal.value = true;
                      api.post("/M/CourseServer/getLiveProductRecord", qs.stringify({courseId:id})).then((res) => {
                          //获取记录
                          if(res.data.data.length===0){
                              //若没有记录则切换显示无抢购记录
                              this.showRush=false
                          }else {
                              this.showRush=true;
                              this.rushList=[];
                              res.data.data.map((item: { userName: string; productName: string })=>{
                                  const obj={userName:'',productName:''};
                                  obj.userName=item.userName.substr(0, 3) + '******' + item.userName.substr(9);
                                  obj.productName=item.productName;
                                  this.rushList.push(obj)
                              });
                          }
                      }).catch((err) => {
                          Toast({
                              type: "error",
                              title: err
                          });
                      });



            }

            function addShopCar(id: string, livePid: string) {
              //先清空提示语，防止弹窗出现上一次提示语
              this.rushMessage='';
                api.post("/M/CourseServer/addLiveProduct", qs.stringify({
                            id,
                            livePid
                        })).then((res) => {
                                if (res.data.msg == '请先登录再执行此操作') {
                                        router.push({
                                            name: "Login",
                                            query: {
                                                redirect: router.currentRoute.value.fullPath,
                                            },
                                        });
                                } else {
                                    //获取提示语
                                    this.rushMessage = res.data.msg;
                                    showModal.value = true;
                                    api.post("/M/CourseServer/getLiveProductRecord", qs.stringify({courseId:id})).then((res) => {
                                        //获取记录
                                      if(res.data.data.length===0){
                                        //若没有记录则切换显示无抢购记录
                                        this.showRush=false
                                      }else {
                                        this.showRush=true;
                                        this.rushList=[];
                                        res.data.data.map((item: { userName: string; productName: string })=>{
                                          const obj={userName:'',productName:''};
                                          obj.userName=item.userName.substr(0, 3) + '******' + item.userName.substr(9);
                                          obj.productName=item.productName;
                                          this.rushList.push(obj)
                                        });
                                      }
                                    }).catch((err) => {
                                      Toast({
                                        type: "error",
                                        title: err
                                      });
                                    });


                                }
                            })
                            .catch((err) => {
                              Toast({
                                type: "error",
                                title: err
                              });
                            });


            }
          function addApply(id: string, livePid: string) {

            api.post("/M/CourseServer/toApplyProduct", qs.stringify({
              id,
              livePid
            })).then((res) => {
              if (res.data.success) {
                Modal({
                  title: "提示",
                  content:res.data.msg,
                });
              }
              else{
                if (res.data.msg == '请先登录再执行此操作') {
                    
                  router.push({
                    name: "Login",
                    query: {
                      redirect: router.currentRoute.value.fullPath,
                    },
                  });
                } else {
                    Modal({
                        title: "提示",
                        content:res.data.msg,
                    });
                }
              }

            })
                .catch((err) => {
                  Toast({
                    type: "error",
                    title: err.message,
                  });
                });
          }
            function unableShop() {
                Toast({
                    type: "error",
                    title: '抢购暂未开启~'
                });
            }

            function back() {
                router.go(-1);
            }

            // function openDesc(index: any) {
            //   (props.list as any).data[index].productDesc = true
            // }
            // function closeDesc(index: any) {
            //   (props.list as any).data[index].productDesc = false
            // }

            return {
                addShopCar,
                addShopCarOver,
                addApply,
                showModal,
                closeModal,
                userInfo,
                back,
                unableShop
            };
        },
    });
</script>

<style lang="scss" scoped>

    .modal-close {
        position: absolute;
        width: 20px;
        height: 20px;
        right: 0;
        top: 0;
        border-radius: 50%;
        border: 0;
    }

    .modal-backdrop {
        /* 当打开模态框时，用半透明的蒙层遮挡网页剩余内容 */
        position: fixed;
        z-index: 99;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);

    }

    .modal-rushMessage {
      font-size: 16px;
      text-align: center;
      color: white;
      height: 130px;
      padding-top: 25px;
      margin: 0 auto;
      width: 80%;
    }

    .modal-body {
        /* 给模态框的主体定位 */
        background: url("https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/f3c95cbfe5a21cfac368f722a770d0e2.png");
        background-size: 100% 100%;
        border-radius: 8px;
        z-index: 99;
        width: 320px;
        height: 340px;
        position: fixed;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        padding: 20px 5px;
        overflow: auto;
        font-size: 14px;
    }

    .modal-body ul {
        text-align: left;
        font-size: 12px;
        height: 150px;
        overflow-y: auto;
    }

    .modal-body ul li {
        margin: 8px auto;
        overflow: hidden;
        white-space: nowrap;
        width: 85%;
        text-overflow: ellipsis;
    }

    .modal-body ul li span {
        color: red;
    }

    .product-ul {
        overflow: hidden;
    }

    // list data

    .product-li {
        width: 100%;
        height: 100%;
        // padding: 15px 10.012px;
        overflow: hidden;
        box-sizing: border-box;
        border-bottom: 1px solid #f4f4f4;

        .product-img {
            width: 73px;
            height: 73px;
            float: left;
            position: relative;

            img {
                width: 100%;
                height: 100%;
            }

            .serial-num {
                position: absolute;
                width: 18px;
                height: 18px;
                background: #b9135a;
                top: 0;
                left: 0;
                color: #fff;
                font-size: 12px;
                text-align: center;
                line-height: 18px;
            }
        }

        .product-info-wrap {
            height: 73px;
            margin-left: 2.15rem;
        }

        .product-title {
            // font-size: 16px;
            font-size: 14.8px;
            // color: #333;
            color: #111;
            line-height: 17.5px;
            // height: 32px;
            padding-right: 42.375px;
            box-sizing: border-box;
            // margin-bottom: 8px;
            margin-bottom: 5px;
            position: relative;
            overflow: hidden;

            span {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                line-clamp: 1;
                -webkit-box-orient: vertical;
            }

            img {
                position: absolute;
                // right: 23px;
                right: 12px;
                top: 0;
                width: 17px;
                height: 16px;
                z-index: 1;
            }
        }

        .now-buy {
            position: absolute;
            right: 0px;
            top: 0;
            z-index: 1;
            padding: 5px 15px;
            background: #b9135a;
            color: #fff;
            border-radius: 45px;
        }

        .product-spot {
            color: #20c2d6;
        }

        .product-card-info {
            // font-size: 14px;
            font-size: 12px;
            color: #999;
            line-height: 16px;
            // margin-bottom: 12px;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            box-sizing: border-box;

            // padding-right: 42.375px;
            width: 92%;
            // display: flex;
            // justify-content: space-between;
            .wy-brand {
                background: #fff;
                color: #b9135a;
                padding: 0 5px;
                margin-left: 10px;
                font-size: 10px;
                border: 0.3px solid #b9135a;
            }

            .price {
                color: #b9135a;
            }
        }

        .product-card-brand {
            color: #999;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            //padding-right: 5px;
            // font-size: 14px;
            font-size: 12px;
            color: #999;
            // line-height: 16px;
            line-height: 19px;

            .price {
                color: #b9135a;
                // margin-left: 10px;
                position: relative;
                left: -2px;
            }

            .market-price {
                font-size: 10px;
                margin-left: 6px;
                text-decoration: line-through;
            }
        }
    }

    @keyframes skeleton-loading {
        0% {
            background-position: 100% 50%;
        }
        to {
            background-position: 0 50%;
        }
    }

    .skeleton {
        .product-card-info {
            height: 16px;
            width: 70% !important;
        }

        .product-card-brand {
            height: 14px;
            width: 50% !important;
        }

        .skeleton-bg {
            background: linear-gradient(
                            90deg,
                            hsla(0, 0%, 74.5%, 0.2) 25%,
                            hsla(0, 0%, 50.6%, 0.24) 37%,
                            hsla(0, 0%, 74.5%, 0.2) 63%
            );
            background-size: 400% 100%;
            animation: skeleton-loading 1.4s ease infinite;
        }
    }

    .save-area {
        width: 100%;
        height: 45px;
        position: relative;
        top: 50px;
        display: flex;
        justify-content: space-evenly;
        position: absolute;
        top: 58%;
    }

    .save-area a {
        display: inline-block;
        width: 35%;
        height: 45px;
        background-color: #999;
        // box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
        border-radius: 3.75px;
        line-height: 45px;
        text-align: center;
        color: #ffffff !important;
        font-size: 15px;
        border-radius: 25px;
    }

    .save-area .save {
        width: 35%;
        height: 45px;
        background-color: #E00024;
        // box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
        border-radius: 3.75px;
        line-height: 45px;
        text-align: center;
        color: #ffffff;
        border: none;
        outline: none;
        font-size: 15px;
        border-radius: 25px;
    }

    // 产品说明
    .product-desc {
        background: #F4F4F4;
        padding: 15px 10.012px;
    }

    .product-desc table {
        font-size: 12px;
        border-collapse: collapse;
        border-color: #eee;

    }

    .product-desc table thead {
        background: #666666;
        color: #fff;
    }

    th {
        color: #fff;
        text-align: center;
        height: 30px;
        box-sizing: border-box;
        padding: 0 9px;
        line-height: 30px;
        font-size: 12px;
    }

    td {
        text-align: center;
        font-size: 12px;
        color: #666666;
        padding: 10.013px;
        word-break: break-word;
    }

    .bncc-desc td {
        padding: 5px 10px;
        text-align: left;
    }

    .bncc-name {
        background-color: #666666;
        color: #fff;
        width: 80px;
        text-align: left;
    }

    .expend {
        width: 12px;
        height: auto;
        vertical-align: middle;
        position: relative;
        top: -1px;
        transform: rotateZ(0);
        transition: transform 0.5s;
    }

    .expended {
        position: relative;
        top: -2px;
        transform: rotateZ(-180deg);
        transition: transform 0.5s;
    }
    .shenq{
      display: inline-block;
      width: 70px;
      height: 26px;
      float: right;
      background: #df0024;
      color: #fff;
      text-align: center;
      line-height: 26px;
      border-radius: 45px;
    }
</style>
<style>
    .wy-brand {
        background: #d64b4b;
        color: #fff;
        padding: 0 5px;
        margin-left: 10px;
        font-size: 10px;
    }
</style>
